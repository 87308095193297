import { PAYROLL_MEN_CARDS } from "../../constants/landingpage_payroll/landingpage_payroll";
const MenCard = () => {
  return (
    <div className=" absolute w-[250px] h-[275px] bg-white rounded-[30px] shadow-lg shadow-light left-[210px] top-[360px] z-30">
      <div>
        <img src={PAYROLL_MEN_CARDS.menimg} alt="Men" className="p-[11px]" />
        <div className="flex items-center justify-between p-[11px]">
          <p className=" font-eina-bold font-[400] text-[16px] text-payroll_primary">
            {PAYROLL_MEN_CARDS.salary}
          </p>
          <p className=" font-eina-bold font-[400] text-[10px] text-payroll_primary opacity-[35%]">
            {PAYROLL_MEN_CARDS.date}
          </p>
        </div>
        <div className="w-[227px] border-[1px] bg-payroll_primary opacity-[15%] mx-[11px]"></div>
        <div className="flex justify-between p-[11px]">
          <p className=" font-eina font-[400] text-[10px] text-payroll_primary opacity-[55%]">
            {PAYROLL_MEN_CARDS.total_title}
          </p>
          <p className=" font-eina-bold font-[400] text-[16px] text-payroll_primary">
            {PAYROLL_MEN_CARDS.total_amount}
          </p>
        </div>
        <div className="flex justify-between px-[11px]">
          <p className=" font-eina font-[400] text-[10px] text-payroll_primary opacity-[55%]">
            {PAYROLL_MEN_CARDS.bonus_title}
          </p>
          <p className=" font-eina-bold font-[400] text-[16px] text-payroll_primary">
            {PAYROLL_MEN_CARDS.bonus_amount}
          </p>
        </div>
      </div>
    </div>
  );
};
export default MenCard;
