import {
  PAYROLL_HEADER_TITLE,
  PAYROLL_HEADER_TABS,
  PAYROLL_LOGIN,
  PAYROLL_SIGNUP,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const Topheader = () => {
  console.log(111);
  return (
    <div className="flex justify-between items-center pt-[30px] mx-[98px]">
      <h1 className=" font-eina-bold font-[400] text-[32px] line-[44px]  text-[#011165]">
        {PAYROLL_HEADER_TITLE}
      </h1>
      <ul className="flex gap-[51px] cursor-pointer  text-[#011165]">
        {PAYROLL_HEADER_TABS.map((tab) => (
          <li key={tab.label}>{tab.label}</li>
        ))}
      </ul>
      <div className="flex items-center gap-[41px]">
        <p className="font-enima font-[400] text-[16px] line-[22px] text-[#011165] cursor-pointer">
          {PAYROLL_LOGIN}
        </p>
        <div className="w-[100px] h-[42px] rounded-[100px] bg-[#FF5800] cursor-pointer">
          {" "}
          <p className=" font-enima font-[400] text-[16px] line-[22px] text-[#ffffff] pt-[10px] text-center">
            {PAYROLL_SIGNUP}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Topheader;
