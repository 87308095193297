import React, { useState } from "react"; // Ensure React and useState are imported
import {
  LOGIN_PSVG,
  LOGIN_CONSTANT_DATAS,
} from "../../../constants/Auth/Login";

const Login = () => {
  // State for storing form data
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  // Handler to update state on user input
  const handleChange = (e) => {
    const { name, value } = e.target; // Destructure name and value from event target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Update the corresponding field in state
    }));
  };

  // Optional: Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log(formData); // For demonstration, log the current state to the console
    // Here you can also call an API to submit the formData
  };
  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className="flex justify-between bg-payroll_primary h-screen">
      <div>
        <p className="font-eina-bold font-[900] text-[36px] text-white opacity-[25%] pt-[48px] pl-[75px]">
          {LOGIN_PSVG.title}
        </p>
        <img src={LOGIN_PSVG.svg} alt="P" className="pt-[84px]" />
      </div>
      <div className="w-[505px] h-[729px] rounded-[30px] shadow-2xl shadow-[#00000066] mt-[148px] flex flex-col  px-[68px] pt-[85px] mr-[75px]">
        {/* Form starts here */}
        <form onSubmit={handleSubmit} className="space-y-6">
          <p className=" font-eina-bold font-[700] text-[36px] text-white">
            {LOGIN_CONSTANT_DATAS.title}
          </p>
          <p className="font-eina-bold font-[500] text-[22px] mt-[30px] text-white">
            <span style={{ color: "#D64A00", fontFamily: "eina-bold" }}>
              Welcome back!
            </span>{" "}
            Log in to Continue
          </p>
          <div>
            <label
              htmlFor="email"
              className=" font-eina font-[500] text-[16px] text-white mt-[53px]"
            >
              {LOGIN_CONSTANT_DATAS.email}
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={"Enter email"}
              className="h-[50px] px-4 py-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500  bg-[#D9D9D9] bg-opacity-[30%] pr-[50px]  text-[#D9D9D9] mt-[17px]" // Increased padding-right to make room for the icon
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="  font-eina font-[500] text-[16px] text-white mt-[20px]"
            >
              {LOGIN_CONSTANT_DATAS.passowrd}
            </label>
            <div className="flex justify-between items-center mt-[17px] relative w-full">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder={"Enter passowrd"}
                className="h-[50px] px-4 py-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 bg-[#D9D9D9] bg-opacity-[30%] pr-[50px]  text-[#D9D9D9]" // Increased padding-right to make room for the icon
              />
              <img
                src={LOGIN_CONSTANT_DATAS.showsvg}
                alt="Show"
                className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer" // Positioned to the right
                onClick={togglePasswordVisibility} // Assuming you have a function to toggle the visibility
              />
            </div>
          </div>
          <div className="text-right">
            <a
              href="#"
              className=" font-eina font-[400] text-[14px] text-[#C4c4c4] mt-[26px]"
            >
              {LOGIN_CONSTANT_DATAS.forgot}
            </a>
          </div>
          <button
            type="submit"
            className="w-full h-[60px] py-2 rounded-md border-[1px] border-white text-white font-medium hover:bg-indigo-500 mt-[37px] shadow-lg shadow-[#FFFFFF22]"
          >
            {LOGIN_CONSTANT_DATAS.btn}
          </button>
        </form>
        <p className=" font-eina font-[400] text-[14px] text-white opacity-[70%] mt-[34px]">
          Don’t have an account?{" "}
          <a
            href="/signup"
            className=" font-eina text-[14px] hover:text-indigo-500 text-[#D64A00]"
          >
            Create free account
          </a>
        </p>
        {/* Form ends here */}
      </div>
    </div>
  );
};

export default Login;
