import LeftSideBar from "../Layout/sidebar/LeftSideBar";

const Dashboard = () => {
  return (
    <div>
      <LeftSideBar />
    </div>
  );
};
export default Dashboard;
