import { PAYROLL_APPROVE_CARD_DATAS } from "../../constants/landingpage_payroll/landingpage_payroll";

const ApprovePayrollCard = () => {
  return (
    <div className="absolute  w-[346px] h-[252px] rounded-[30px] bg-white right-[280px] bottom-[-116px] shadow-lg shadow-light z-20">
      <div className="flex justify-between px-[29px] mt-[33px]">
        <p className="font-eina-bold font-[400] text-[20px] text-payroll_primary">
          {PAYROLL_APPROVE_CARD_DATAS.title}
        </p>
        <p className="font-ina-bold font-[400] text-[20px]  text-payroll_primary opacity-[35%]">
          {PAYROLL_APPROVE_CARD_DATAS.date}
        </p>
      </div>
      <div className="w-[279px] bg-payroll_primary opacity-[15%] border-[1px] mx-[32px] my-[20px]"></div>
      <div className="flex justify-between px-[29px]">
        <p className="font-eina font-[400] text-[10px] line-[50px] text-center text-payroll_primary">
          {PAYROLL_APPROVE_CARD_DATAS.total_title}
        </p>
        <p className="font-eina font-[400] text-[10px] line-[50px] text-center text-payroll_primary">
          {PAYROLL_APPROVE_CARD_DATAS.tax_title}
        </p>
      </div>
      <div className="flex justify-between px-[29px] pt-[10px]">
        <p className="font-eina-bold font-[400] text-[15px] text-center text-payroll_primary">
          {PAYROLL_APPROVE_CARD_DATAS.total_amount}
        </p>
        <p className="font-eina-bold font-[400] text-[15px] text-center text-payroll_primary">
          {PAYROLL_APPROVE_CARD_DATAS.tax_amount}
        </p>
      </div>
      <div className="flex items-center justify-between pt-[10px] px-[29px]">
        <div className="w-[115px] h-[52px] bg-payroll_primary gap-[10px] rounded-[50px] cursor-pointer">
          <p className="font-eina-bold font-[400] text-white text-[13px] text-center py-[18px]">
            {PAYROLL_APPROVE_CARD_DATAS.btn}
          </p>
        </div>
        <p className="font-eina font-[400] text-[14px] underline cursor-pointer">
          {PAYROLL_APPROVE_CARD_DATAS.check_details}
        </p>
      </div>
    </div>
  );
};
export default ApprovePayrollCard;
