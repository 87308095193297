import React from "react"; // Ensure React is imported if using JSX

// Modify the function to accept props
const CompanyProduct = ({ imgSrc, title, description }) => {
  return (
    <div className="w-[320px] h-[304px] bg-[#F0F0F0] rounded-[30px] border-[1px] border-[#EDEDED] transition duration-300 ease-in-out transform group-hover:scale-105 shadow-lg shadow-[#a0a0a0]">
      <div className="flex justify-center items-center w-[65px] h-[65px] bg-[#E6E6E6] rounded-[10px] ml-[44px] mt-[37px]">
        {/* Use the imgSrc prop for the image source */}
        <img src={imgSrc} alt={title} width={40} />
      </div>
      {/* Use the title prop for the h1 content */}
      <h1 className="font-[700] text-[18px] pt-[30px] pl-[44px]">{title}</h1>
      {/* Use the description prop for the paragraph content */}
      <p className="font-[400] text-[18px] tracking-[1px] text-[#969696] pl-[44px]">
        {description}
      </p>
    </div>
  );
};

export default CompanyProduct;
