import LandingPage from "./pages/landing-prosphere/index";
import "./App.css";
import Routers from "./Route/index";
function App() {
  return (
    <div className="App">
      <Routers />
    </div>
  );
}

export default App;
