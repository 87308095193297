import SurveyEmployeeCard from "../../components/landingpage_prosphere/SurveyEmployeeCard";
import {
  SURVEY_HEADER,
  SURVEY_CARD_DATAS,
} from "../../constants/landingpage_prosphere/landingpage_prosphere";
const Survey = () => {
  return (
    <div className="mt-[126px]">
      <h1 className="w-[792px] font-[900] font-acumin-bold line-[57px] text-[48px] tracking-[2px] text-center mx-auto ">
        {SURVEY_HEADER.title}
      </h1>
      <p className="w-[792px] font-[400] font-acumin text-[16px] line-[22px] tracking-[1px] text-center text-[#969696] mx-auto ">
        {SURVEY_HEADER.description}
      </p>
      <div className="flex items-center justify-center gap-[24px]">
        {SURVEY_CARD_DATAS.map((data, index) => (
          <SurveyEmployeeCard
            key={index}
            imgSrc={data.imgSrc}
            title={data.title}
            description={data.description}
          />
        ))}
      </div>
    </div>
  );
};
export default Survey;
