import React from "react";
import {
  TESTIMONIALS_DATAS,
  TESTIMONIALS_CARD_DATRAS,
} from "../../constants/landingpage_prosphere/landingpage_prosphere";
import TestimonialsCard from "../../components/landingpage_prosphere/TestimonialsCard";
import { Swiper, SwiperSlide } from "swiper/react";
import Paginationfrom from "swiper";
import "swiper/css"; // Import Swiper styles
import "swiper/css/effect-coverflow"; // Import Swiper Coverflow Effect styles
import "swiper/css/pagination"; // Import Swiper Pagination styles
import "swiper/css"; // Import Swiper styles
const Testimonials = () => {
  const titleParts = TESTIMONIALS_DATAS.title.split("Testimonials");
  return (
    <div className="w-full h-[800px] bg-[#2F1893]">
      <div className="relative flex justify-center items-center mt-[303px] pt-[123px]">
        <div>
          {/* Position imgSrc_1 at the left bottom of the container */}
          <img
            src={TESTIMONIALS_DATAS.imgSrc_1}
            alt="Testimonial 1"
            className="absolute left-[426px] top-[323px]"
          />

          {/* Centered text block */}
          <div>
            <h1 className=" font-acumin-bold w-[508px] font-[900] text-[48px] line-[57px] tracking-[1px] text-white text-center">
              {titleParts[0]}
              <span style={{ color: "#FD853A", fontFamily: "eina-bold" }}>
                Testimonials
              </span>
              {titleParts[1]}
            </h1>
            <p className="w-[508px] font-[400] text-[18px] font-acumin line-[25px] tracking-[1px] text-white text-center">
              {TESTIMONIALS_DATAS.description}
            </p>
          </div>

          {/* Position imgSrc_2 at the right top of the container */}
          <img
            src={TESTIMONIALS_DATAS.imgSrc_2}
            alt="Testimonial 2"
            className="absolute right-[456px] bottom-[209px]"
          />

          {/* Centered text block */}
        </div>
      </div>
      <Swiper
        loop={true}
        effect="coverflow"
        centeredSlides={true}
        slidesPerView={"1.8"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false, // You might want to disable slide shadows for a cleaner look
        }}
        pagination={{
          clickable: true,
        }}
        grabCursor={true}
        spaceBetween={24}
        className="carousel"
      >
        {TESTIMONIALS_CARD_DATRAS.map((data, index) => (
          <SwiperSlide key={index}>
            <TestimonialsCard
              imgSrcAvatar={data.imgSrcAvatar}
              name={data.name}
              role={data.role}
              imgSrcScore={data.imgSrcScore}
              score={data.score}
              description={data.description}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonials;
