import Topheader from "./Topheader";
import Footer from "./Footer";
import Sorem from "./Sorem";
import Products from "./Products";
import Survey from "./Survey";
import Testimonials from "./Testimonials";

const LandingPage_ProsPhere = () => {
  return (
    <div>
      <Topheader />
      <Sorem />
      <Products />
      <Survey />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default LandingPage_ProsPhere;
