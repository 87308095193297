const SurveyEmployeeCard = ({ imgSrc, title, description }) => {
  return (
    <div className="flex items-center justify-center w-full max-w-[285px] h-[320px] rounded-[30px] bg-white shadow-lg mt-[61px] overflow-hidden transition duration-500 ease-in-out transform hover:-translate-y-2 hover:shadow-xl">
      <div className="text-center">
        <img
          src={imgSrc}
          alt={title}
          className="w-10 h-10 m-auto rounded-full"
        />
        <h1 className="font-bold text-[18px] pt-[27px]">{title}</h1>
        <p className="text-[18px] font-normal tracking-[1px] text-gray-600 px-[23px] pt-[23px]">
          {description}
        </p>
      </div>
    </div>
  );
};

export default SurveyEmployeeCard;
