import {
  TITLE,
  HEADER_TABS,
} from "../../constants/landingpage_prosphere/landingpage_prosphere";
import ThreeLineImage from "../../assets/images/landingpage_prosphere/svg/three-lines.svg";
import WomanImage from "../../assets/images/landingpage_prosphere/png/woman.png";
const Topheader = () => {
  return (
    <div className="flex items-center justify-between pl-[115px] pr-[491px] gap-[248px] py-[29px]">
      <p className="text-[24px] font-acumin-bold font-[900]">{TITLE}</p>
      <ul className="flex gap-[38px] cursor-pointer">
        {HEADER_TABS.map((tab) => (
          <li key={tab.label}>{tab.label}</li>
        ))}
      </ul>
    </div>
  );
};
export default Topheader;
