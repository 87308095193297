import SolarSVG from "../../assets/images/landingpage_prosphere/svg/solar.svg";
import ScoreSVG from "../../assets/images/landingpage_prosphere/svg/score.svg";
import LearningSVG from "../../assets/images/landingpage_prosphere/svg/learning.svg";
import Card_PaySVG from "../../assets/images/landingpage_prosphere/svg/card_pay.svg";
import SurveySVG from "../../assets/images/landingpage_prosphere/svg/survey.svg";
import CalendarSVG from "../../assets/images/landingpage_prosphere/svg/calendar.svg";

import Employee_OpinionSVG from "../../assets/images/landingpage_prosphere/svg/employee_opinion.svg";
import Employee_EngagementSVG from "../../assets/images/landingpage_prosphere/svg/employee_engagement.svg";
import Organization_ClimateSVG from "../../assets/images/landingpage_prosphere/svg/organizational_climate.svg";
import Training_NeedsSVG from "../../assets/images/landingpage_prosphere/svg/training_needs.svg";
import Star_1SVG from "../../assets/images/landingpage_prosphere/svg/star_1.svg";
import Star_2SVG from "../../assets/images/landingpage_prosphere/svg/star_2.svg";

import imgSrcAvatar from "../../assets/images/landingpage_prosphere/svg/avatar.svg";
import imgSrcScore from "../../assets/images/landingpage_prosphere/svg/starscore.svg";

export const TITLE = "PROSPHERE";
export const HEADER_TABS = [
  {
    label: "Home",
    url: "#",
  },
  {
    label: "About Us",
    url: "#",
  },
  {
    label: "Products",
    url: "#",
  },
  {
    label: "Pricing",
    url: "#",
  },
  {
    label: "FAQs",
    url: "#",
  },
];

export const LANDING_SECTION = {
  heading: "Sorem ipsum dolor sit amet.",
  description:
    "Lorem ipsum dolor sit amet consectetur. Cras mauris tellus nunc suspendisse ut lorem. Id parturient rhoncus ac tristique.",
};
export const FOOTER_COMPANY = {
  title: "Our Company",
  aboutus: "About Us",
  faqs: "FAQs",
};
export const FOOTER_PROSPHERE = {
  title: "PROSPHERE",
  street: "5 Bathrust Street Wuse 2  Abuja, FCT",
};
export const FOOTER_PRODUCTS = [
  {
    label: "Pulse Check",
    url: "#",
  },
  {
    label: "Payroll",
    url: "#",
  },
  {
    label: "Survey",
    url: "#",
  },
  {
    label: "Leave Scheduler",
    url: "#",
  },
  {
    label: "Enagagement Survey",
    url: "#",
  },
  {
    label: "Employee Net Promoter Score",
    url: "#",
  },
];

export const FOOTER_CONTACTUS = {
  title: "Contact Us",
  number: "0903 669 1857",
};
export const FOOTER_DOWNLOADAPP = "Download App";
export const FOOTER_COPYRIGHT = "2024 PROSphere. All rights reserved";
export const FOOTER_TERM = "Terms of use";
export const FOOTER_PRIVACY = "Privacy policy";
export const FOOTER_OURCOMPANTY = "Our Company";
export const FOOTER_PRODUCT = "Products";
export const PRODUCTS_PRODUCTS = {
  title: "Our Products",
  description:
    "Start your journey towards a more informed HR strategy with our user-friendly survey system. ",
};
export const PRODUCTS_PULSE_CHECK = {
  title: "Pulse Check",
  description: "Sorem ipsum dolor sit amet",
};

export const PRODUCT_CARD_DATAS = [
  {
    imgSrc: SolarSVG, // Update the path as needed
    title: "Product 1",
    description: "Sorem ipsum dolor sit amet.",
  },
  {
    imgSrc: Card_PaySVG,
    title: "Product 2",
    description: "Sorem ipsum dolor sit amet.",
  },
  {
    imgSrc: CalendarSVG,
    title: "Product 3",
    description: "Sorem ipsum dolor sit amet.",
  },
  {
    imgSrc: SurveySVG,
    title: "Product 4",
    description: "Sorem ipsum dolor sit amet.",
  },
  {
    imgSrc: LearningSVG,
    title: "Product 5",
    description: "Sorem ipsum dolor sit amet.",
  },
  {
    imgSrc: ScoreSVG,
    title: "Product 6",
    description: "Sorem ipsum dolor sit amet.",
  },
];
export const SURVEY_HEADER = {
  title: "Streamline Your HR Processes with Easy-to-Use Surveys",
  description:
    "Start your journey towards a more informed HR strategy with our user-friendly survey system. Simplify data collection and analysis for better decision-making",
};

export const SURVEY_CARD_DATAS = [
  {
    imgSrc: Employee_OpinionSVG, // Update the path as needed
    title: "Employee Opinion",
    description:
      "Conduct thorough performance reviews to foster employee growth and productivity",
  },
  {
    imgSrc: Employee_EngagementSVG,
    title: "Employee Engagement",
    description:
      "Conduct thorough performance reviews to foster employee growth and productivity",
  },
  {
    imgSrc: Organization_ClimateSVG,
    title: "Organizational Climate",
    description:
      "Conduct thorough performance reviews to foster employee growth and productivity",
  },
  {
    imgSrc: Training_NeedsSVG,
    title: "Training Needs",
    description:
      "Conduct thorough performance reviews to foster employee growth and productivity",
  },
];
export const TESTIMONIALS_DATAS = {
  title: "Let Our Testimonials Speak for us",
  description:
    "HR Survey System is dedicated to enhancing your human resources strategy through comprehensive and customizable surveys. ",
  imgSrc_1: Star_1SVG,
  imgSrc_2: Star_2SVG,
};

export const TESTIMONIALS_CARD_DATRAS = [
  {
    imgSrcAvatar: imgSrcAvatar,
    name: "Jayesh Patill",
    role: "CEO, PayPal",
    imgSrcScore: imgSrcScore,
    score: "5.0",
    description:
      "consectetur adipiscing elit. Sed congue interdum ligula a dignissim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis orci elementum egestas lobortis.Sed lobortis orci elementum egestas lobortis.Sed lobortis orci elementum egestas lobortis.",
  },
  {
    imgSrcAvatar: imgSrcAvatar,
    name: "Jayesh Patill",
    role: "CEO, PayPal",
    imgSrcScore: imgSrcScore,
    score: "5.0",
    description:
      "consectetur adipiscing elit. Sed congue interdum ligula a dignissim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis orci elementum egestas lobortis.Sed lobortis orci elementum egestas lobortis.Sed lobortis orci elementum egestas lobortis.",
  },
  {
    imgSrcAvatar: imgSrcAvatar,
    name: "Jayesh Patill",
    role: "CEO, PayPal",
    imgSrcScore: imgSrcScore,
    score: "5.0",
    description:
      "consectetur adipiscing elit. Sed congue interdum ligula a dignissim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis orci elementum egestas lobortis.Sed lobortis orci elementum egestas lobortis.Sed lobortis orci elementum egestas lobortis.",
  },
  {
    imgSrcAvatar: imgSrcAvatar,
    name: "Jayesh Patill",
    role: "CEO, PayPal",
    imgSrcScore: imgSrcScore,
    score: "5.0",
    description:
      "consectetur adipiscing elit. Sed congue interdum ligula a dignissim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis orci elementum egestas lobortis.Sed lobortis orci elementum egestas lobortis.Sed lobortis orci elementum egestas lobortis.",
  },
];
