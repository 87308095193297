import React, { useState } from "react";
import {
  LOGIN_CONSTANT_DATAS,
  LOGIN_PSVG,
} from "../../../constants/Auth/Login";

const SignUp = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    position: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // API call to register the user would go here
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <div className="flex justify-between bg-payroll_primary h-screen">
      <div>
        <p className="font-eina-bold font-[900] text-[36px] text-white opacity-[25%] pt-[48px] pl-[75px]">
          {LOGIN_PSVG.title}
        </p>
        <img src={LOGIN_PSVG.svg} alt="P" className="pt-[84px]" />
      </div>

      <div className="w-[575px] h-[746px] rounded-[30px] bg-payroll_primary shadow-custom shadow-[#00000033] mt-[89px] mr-[106px] px-[68px] pt-[55px]">
        <form onSubmit={handleSubmit}>
          <h2 className="font-eina-bold font-[700] text-[36px] text-white opacity-[70%]">
            CREATE ACCOUNT
          </h2>
          <p className="font-eina-bold font-[500] text-[22px] mt-[30px] text-white opacity-[70%]">
            <span style={{ color: "#D64A00", fontFamily: "eina-bold" }}>
              Create account
            </span>{" "}
            to get started!
          </p>

          <InputField
            label="Company name"
            name="companyName"
            value={formData.companyName}
            handleChange={handleChange}
            placeholder={"Enter company name"}
          />
          <InputField
            label="Position/Role"
            name="position"
            value={formData.position}
            handleChange={handleChange}
            placeholder={"Enter position"}
          />
          <InputField
            label="Email address"
            name="email"
            value={formData.email}
            handleChange={handleChange}
            placeholder={"Enter email address"}
          />
          <div className="flex justify-between gap-4">
            <InputField
              label="First name"
              name="firstName"
              value={formData.firstName}
              handleChange={handleChange}
              placeholder={"Enter first name"}
            />
            <InputField
              label="Last name"
              name="lastName"
              value={formData.lastName}
              handleChange={handleChange}
              placeholder={"Enter last name"}
            />
          </div>
          <div className="flex justify-between gap-4">
            <InputField
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              placeholder="Enter password"
              handleChange={handleChange}
              toggleVisibility={toggleShowPassword}
              isPassword={true}
            />
            <InputField
              label="Confirm password"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              value={formData.confirmPassword}
              placeholder="Confirm password"
              handleChange={handleChange}
              toggleVisibility={toggleShowConfirmPassword}
              isPassword={true}
            />
          </div>

          <button
            type="submit"
            className="w-full h-[60px] py-2 rounded-md border-[1px] border-white text-white font-medium hover:bg-indigo-500 mt-[37px] shadow-lg shadow-[#FFFFFF22] opacity-[70%]"
          >
            Create account
          </button>
        </form>
        <p className="font-eina font-[400] text-[14px] text-white opacity-[70%] mt-[20px]">
          Already have an account?{" "}
          <a href="/login" className="text-[#D64A00] hover:text-indigo-500">
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

const InputField = ({
  label,
  name,
  value,
  handleChange,
  type = "text",
  placeholder,
  toggleVisibility,
  isPassword,
}) => {
  // Assuming you have another SVG for "hide" or you use the same for both actions
  const showSvg = LOGIN_CONSTANT_DATAS.showsvg; // URL to your 'show' SVG icon

  return (
    <div className="w-full  relative">
      <label
        htmlFor={name}
        className="block font-eina font-[500] text-[16px] text-white pt-[5px] opacity-[70%]"
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className="mt-[5px] h-[50px] px-4 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pr-[50px] bg-[#D9D9D9] bg-opacity-[30%] text-[#D9D9D9]" // Padding-right to make room for the icon
      />
      {isPassword ? (
        <img
          src={showSvg}
          alt={"Show"}
          className="absolute right-[2px] bottom-[10px] transform -translate-y-1/2 cursor-pointer"
          onClick={toggleVisibility}
        />
      ) : null}
    </div>
  );
};

export default SignUp;
