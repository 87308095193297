import {
  TITLE,
  FOOTER_PROSPHERE,
  FOOTER_COMPANY,
  FOOTER_PRODUCTS,
  FOOTER_CONTACTUS,
  FOOTER_DOWNLOADAPP,
  FOOTER_COPYRIGHT,
  FOOTER_PRIVACY,
  FOOTER_TERM,
  FOOTER_OURCOMPANTY,
  FOOTER_PRODUCT,
} from "../../constants/landingpage_prosphere/landingpage_prosphere";
import AppleStore from "../../assets/images/landingpage_prosphere/svg/applestore.svg";
import PleayStore from "../../assets/images/landingpage_prosphere/svg/playstore.svg";
const Footer = () => {
  return (
    <div>
      <div className="flex px-[94px] justify-between pb-[204px] pt-[117px]">
        {/* Prosphere */}
        <div className="w-[140px]">
          <h1 className="text-[24px] font-acumin-bold font-[400]">{TITLE}</h1>
          <p className=" font-acumin font-[400] text-[16px] pt-[40px]">
            {FOOTER_PROSPHERE.street}
          </p>
        </div>
        {/* Our Company */}
        <div className="w-[136px]">
          <h1 className="text-[20px] font-acumin-bold font-[400]">
            {FOOTER_COMPANY.title}
          </h1>
          <p className=" font-acumin text-[16px] font-[400] mt-[40px]">
            {FOOTER_COMPANY.aboutus}
          </p>
          <p className="font-acumin text-[16px] font-[400] mt-[24px]">
            {FOOTER_COMPANY.faqs}
          </p>
        </div>
        {/* Products */}
        <div className="w-[467px]">
          <h1 className="text-[20px] font-acumin-bold font-[400]">
            {FOOTER_PRODUCT}
          </h1>
          <ul className="text-[16px] font-[400] mt-[40px] columns-2">
            {FOOTER_PRODUCTS.map((tab) => (
              <li
                key={tab.label}
                className="mr-[0px] mb-[24px] break-inside-avoid"
              >
                {tab.label}
              </li>
            ))}
          </ul>
        </div>
        {/* Contact Us */}
        <div className="w-[136px] h-[140px]">
          <h1 className="text-[20px] font-acumin-bold font-[400]">
            {FOOTER_CONTACTUS.title}
          </h1>
          <p className="text-[16px] font-[400] mt-[40px]">
            {FOOTER_CONTACTUS.number}
          </p>
        </div>

        {/* Download App */}
        <div className="w-[156px] h-[140px]">
          <h1 className="text-[20px] font-acumin-bold font-[400]">
            {FOOTER_DOWNLOADAPP}
          </h1>
          <img
            src={PleayStore}
            alt="Lines"
            width={161}
            height={46}
            className="mt-[40px] cursor-pointer"
          />
          <img
            src={AppleStore}
            alt="Lines"
            width={161}
            height={46}
            className="mt-[15px] cursor-pointer"
          />
        </div>
      </div>
      <div className="h-[1px] bg-primary mx-[114px] mb-[40px]"></div>
      <div className="flex px-[114px] justify-between pb-[29px]">
        <p>{FOOTER_COPYRIGHT}</p>
        <div className="flex">
          {" "}
          {/* This div groups the two paragraphs together */}
          <p className="mr-[24px] cursor-pointer">{FOOTER_PRIVACY}</p>
          <p className=" cursor-pointer">{FOOTER_TERM}</p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
