import ApprovePayrollCard from "../../components/landingpage_payroll/ApprovePayrollCard";
import {
  PAYROLL_LIFE,
  PAYROLL_SUBTRACT_IMG,
  PAYROLL_WOMAN_IMG,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const titleParts = PAYROLL_LIFE.title.split("Payroll");
const Payroll_Life = () => {
  return (
    <div className=" relative flex items-center  justify-between mt-[83px] pl-[98px]">
      <div>
        <h1 className="w-[608px] font-eina-bold font-[400] text-[55px] text-payroll_primary line-[60px] tracking-[-1px]">
          {titleParts[0]}

          <span style={{ color: "#FF5800", fontFamily: "eina-bold" }}>
            Payroll
          </span>
          {titleParts[1]}
        </h1>
        <p className="w-[588px] font-enia font-[400] text-[16px] line-[22px] text-payroll_primary tracking-[0px]">
          {PAYROLL_LIFE.description}
        </p>
        <div className="flex mt-[24px] items-center gap-[24px]">
          <div className="w-[149px] h-[57px] rounded-[100px] gap-[10px] bg-payroll_primary cursor-pointer">
            <p className="font-eina font-[400] text-[20px] text-white text-center pt-[15px]">
              {PAYROLL_LIFE.btn}
            </p>
          </div>
          <div className="flex items-center cursor-pointer">
            <img src={PAYROLL_LIFE.demoImg} alt="Demo" />
            <p className="font-eina-bold font-[400] text-[16px] line-[21px] text-[#FF5800] pl-[10px]">
              {PAYROLL_LIFE.demo}
            </p>
          </div>
        </div>
      </div>
      <div>
        <img
          src={PAYROLL_SUBTRACT_IMG}
          width={117}
          alt="Subtract"
          className="absolute right-[436px] top-[-58px] z-0"
        />
        <ApprovePayrollCard />
      </div>
      <img
        src={PAYROLL_WOMAN_IMG}
        width={497}
        height={535}
        alt="Woman"
        className="pr-[102px] z-10"
      />
    </div>
  );
};
export default Payroll_Life;
