import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { authRoutes } from "./AuthRoutes";
// setup fake backend

const Routers = () => {
  const login = useState(JSON.parse(localStorage.getItem("login")))[0];

  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");

  useEffect(() => {}, []);

  return (
    <BrowserRouter basename={"/"}>
      <Suspense>
        <Routes>
          {/* <Route exact path={``} element={<Navigate to={`/payroll`} />} /> */}
          <Route exact path={``} element={<Navigate to={`/prosphere`} />} />
          <Route exact path={`/`} element={<Navigate to={`/prosphere`} />} />
          <Route />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
