import LandingPage_ProsPhere from "../pages/landing-prosphere/index";
import LandingpPage_Payroll from "../pages/landing-payroll/index";
import Login from "../pages/Auth/Login/Login";
import SignUp from "../pages/Auth/SignUp/SignUp";
import Dashboard from "../pages/Dashboard/index";

export const authRoutes = [
  { path: `/prosphere`, Component: <LandingPage_ProsPhere /> },
  { path: `/payroll`, Component: <LandingpPage_Payroll /> },
  { path: `/login`, Component: <Login /> },
  { path: `/signup`, Component: <SignUp /> },
  { path: `/dashboard`, Component: <Dashboard /> },
];
