import MenCard from "../../components/landingpage_payroll/MenCard";
import {
  PAYROLL_SAVE_IMG,
  PAYROLL_SAVE_DATAS,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const Payroll_Save = () => {
  const titleParts = PAYROLL_SAVE_DATAS.title.split("payroll");
  return (
    <div className=" relative flex items-center justify-between mt-[170px] ml-[190px]  mb-[310px]">
      <div className="relative">
        <img
          src={PAYROLL_SAVE_IMG.wave}
          alt="Wave"
          className="absolute left-[-70px] top-[-45px] z-10"
        />
        <img
          src={PAYROLL_SAVE_IMG.womanimg}
          alt="Woman"
          className="relative z-20"
        />
        <MenCard />
      </div>
      <div className="relative pr-[188px]">
        <p className="font-eina-bold font-[400] text-[45px] text-payroll_primary w-[520px]">
          {titleParts[0]}
          <span style={{ color: "#FF5800", fontFamily: "eina-bold" }}>
            payroll
          </span>
          {titleParts[1]}
        </p>
        <p className=" font-eina font-[400] text-[16px] text-payroll_primary opacity-[45%] w-[420px] pt-[30px]">
          {PAYROLL_SAVE_DATAS.description}
        </p>
        <p className=" font-eina-bold font-[400] text-[16px] text-payroll_primary pt-[20px]">
          {PAYROLL_SAVE_DATAS.learnmore}
        </p>
      </div>
    </div>
  );
};
export default Payroll_Save;
