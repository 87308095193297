import {
  HEADER_TABS,
  LANDING_SECTION,
} from "../../constants/landingpage_prosphere/landingpage_prosphere";
import ThreeLineImage from "../../assets/images/landingpage_prosphere/svg/three-lines.svg";
import WomanImage from "../../assets/images/landingpage_prosphere/png/woman.png";
const Sorem = () => {
  return (
    <div className="flex flex-col justify-between mt-[85px] px-[109px] lg:flex-row lg:items-center lg:px[50px]">
      <div className="w-[612px] flex flex-col gap-[30px] mr-[66px]">
        <img src={ThreeLineImage} alt="Lines" width={42} height={29} />
        <h1 className="font-[900] font-acumin-bold line-[60px] text-[64px] tracking-[2px]">
          {LANDING_SECTION.heading}
        </h1>
        <p className="text-light font-acumin text-[18px] line-[21px] tracking-[1px]">
          {LANDING_SECTION.description}
        </p>
        <button className="px-[49px] py-[14px] bg-primary w-[290px] h-[55px] rounded-full line-[26px] text-[20px] text-white">
          Get Started for Free
        </button>
      </div>
      <img src={WomanImage} alt="woman" width={531} height={531} />
    </div>
  );
};
export default Sorem;
