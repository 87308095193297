import React from "react";

// Updated to accept props
const TestimonialsCard = ({
  imgSrcAvatar,
  name,
  role,
  imgSrcScore,
  score,
  description,
}) => {
  return (
    <div className="relative justify-center w-[784px] h-[265px] rounded-[24px] bg-[#FFFFFF0E] mt-[72px] mx-auto hover:before:content-[''] hover:before:absolute hover:before:inset-0 hover:before:bg-black hover:before:bg-opacity-50 hover:before:rounded-[24px] transition">
      <div className="flex pl-[21px] pt-[21px] gap-[12px]">
        {/* Use props for the avatar image source */}
        <img src={imgSrcAvatar} alt="Avatar" width={55} />
        <div>
          {/* Use props for the name */}
          <p className="font-[700] text-[24px] line-[28px] text-[#FFF]">
            {name}
          </p>
          {/* Use props for the role */}
          <p className="font-[400] text-[18px] line-[22px] text-[#FFF]">
            {role}
          </p>
        </div>
      </div>
      <div className="flex items-start pl-[21px] gap-[5px]">
        {/* Use props for the score image source */}
        <img src={imgSrcScore} alt="Score" width={160} height={32} />
        {/* Use props for the score */}
        <p className="font-[500] text-[26px] line-[34px] text-[#FCFCFD]">
          {score}
        </p>
      </div>
      {/* Use props for the description */}
      <p className="font-[400] text-[20px] line-[24px] px-[11px] pt-[14px] text-[#F9FAFB] text-center">
        {description}
      </p>
    </div>
  );
};

export default TestimonialsCard;
