import React from "react";
import Vector from "../../assets/images/landingpage_prosphere/svg/vector.svg";
import {
  PRODUCTS_PRODUCTS,
  PRODUCT_CARD_DATAS,
} from "../../constants/landingpage_prosphere/landingpage_prosphere";
import CompanyProduct from "../../components/landingpage_prosphere/CompanyProductCard";
const Products = () => {
  return (
    <div className="pt-[135px]">
      <img src={Vector} className="pl-[166px]" alt=""></img>
      <div className="flex items-center justify-between">
        <h1 className="font-[900] font-acumin-bold line-[60px] text-[48px] tracking-[2px] pl-[192px]">
          {PRODUCTS_PRODUCTS.title}
        </h1>
        <p className="font-[400] text-[16px] font-acumin tracking-[1px] w-[553px] flex flex-col pr-[157px] text-light">
          {PRODUCTS_PRODUCTS.description}
        </p>
      </div>
      {/* Grid container for CompanyProduct components */}
      <div className="grid grid-cols-3 gap-[48px] pl-[192px]  pt-[78px]">
        {PRODUCT_CARD_DATAS.map((data, index) => (
          <div key={index} className="relative group">
            {/* Shader Effect Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300 ease-in-out"></div>
            <CompanyProduct
              imgSrc={data.imgSrc}
              title={data.title}
              description={data.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
