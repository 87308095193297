import {
  PAYROLL_PROCESS_DATA,
  PAYROLL_PROCESS_CARDS,
  PAYROLL_PROCESS_AUTO,
  PAYROLL_PROCESS_ACCURATE,
  PAYROLL_PROCESS_TAX,
} from "../../constants/landingpage_payroll/landingpage_payroll";
const Payroll_Process = () => {
  const titleParts = PAYROLL_PROCESS_DATA.title.split("payroll");
  return (
    <div className="flex flex-col justify-center items-center bg-[#F2F9FF] mt-[302px]">
      <p className="w-[497px] font-eina-bold font-[400] text-[45px] text-payroll_primary pt-[73px] text-center">
        {titleParts[0]}
        <span style={{ color: "#FF5800", fontFamily: "eina-bold" }}>
          payroll
        </span>
        {titleParts[1]}
      </p>
      <div className="h-[314px] flex mt-[188px] mb-[88px]">
        <div>
          <img src={PAYROLL_PROCESS_AUTO.imgSrc} alt="Automated" />
          <p className=" font-eina-bold font-[400] text-[20px] text-payroll_primary w-[189px] pt-[40px]">
            {PAYROLL_PROCESS_AUTO.title}
          </p>
          <p className=" font-eina font-[400] text-[16px] text-payroll_primary opacity-[45%] pt-[15px] w-[350px]">
            {PAYROLL_PROCESS_AUTO.description}
          </p>
        </div>

        <div className="border-l border-r h-full border-opacity-15 border-payroll_primary px-[53px] mx-[43px]">
          <img src={PAYROLL_PROCESS_ACCURATE.imgSrc} alt="Automated" />
          <p className=" font-eina-bold font-[400] text-[20px] text-payroll_primary w-[219px] pt-[40px]">
            {PAYROLL_PROCESS_ACCURATE.title}
          </p>
          <p className=" font-eina font-[400] text-[16px] text-payroll_primary opacity-[45%] pt-[15px] w-[350px]">
            {PAYROLL_PROCESS_ACCURATE.description}
          </p>
        </div>

        <div>
          <img src={PAYROLL_PROCESS_TAX.imgSrc} alt="Automated" />
          <p className=" font-eina-bold font-[400] text-[20px] text-payroll_primary w-[219px] pt-[40px]">
            {PAYROLL_PROCESS_TAX.title}
          </p>
          <p className=" font-eina font-[400] text-[16px] text-payroll_primary opacity-[45%] pt-[15px] w-[330px]">
            {PAYROLL_PROCESS_TAX.description}
          </p>
        </div>
      </div>

      <div className="w-[157px] h-[57px] bg-payroll_primary rounded-[100px] gap-[10px] mb-[40px] cursor-pointer">
        <p className="font-eina font-[400] text-[20px] text-white px-[20px] py-[15px]">
          {PAYROLL_PROCESS_DATA.btn}
        </p>
      </div>
    </div>
  );
};
export default Payroll_Process;
